import moment from "moment";
import React, {FC, useContext, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router";

import {
    Checkbox,
    NavigationBar,
    NavigationTab,
    Paginator,
    Row,
    SampleStatus,
    SearchField,
    Sortable,
    ToggleSwitch,
} from "../../Components";
import {getUserFacingSampleType} from "../../helpers";
import Icons from "../../Icons";

import {getCounts, getMySite, getSamples} from "../../requests";
import {StateContext} from "../../state";
import {Count, RowBorder, SortOrder, SortType, TabItem} from "../../types";
import "./style.css";

type ILocation = {
    state?: any;
    tab?: TabItem.SamplesToReceive;
};

let currentTab: TabItem;

const ManageSamplesAnalysis: FC = () => {
    const {
        state: {sort, activeTab: stateActiveTab},
        dispatch,
    } = useContext(StateContext);
    const history = useHistory();
    const location = useLocation<ILocation>();
    const [activeTab, setActiveTab] = useState<TabItem>(
        stateActiveTab || TabItem.SamplesToReceive
    );
    const [keyword, setKeyword] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [samples, setSamples] = useState<any[]>([]);
    const [site, setSite] = useState<any>(undefined);
    const [counts, setCounts] = useState<Count>();
    const [showAwaitingToTransport, setShowAwaitingToTransport] =
        useState<boolean>(false);
    const [checkedSamples, setCheckedSamples] = useState<any[]>([]);
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const perPage = 30;

    const changeState = (
        tab: TabItem,
        page: number = 1,
        keyword: string = ""
    ) => {
        if (loading) return;
        if (tab !== currentTab) {
            currentTab = tab;
            dispatch({type: "setActiveTab", payload: tab});
            setActiveTab(tab);
        }
        setPage(page);
        setKeyword(keyword);
    };

    const fetchCounts = async () => {
        const {data} = await getCounts();
        setCounts(data);
    };
    const fetchSite = async () => {
        const {data} = await getMySite();
        setSite(data);
    };

    const fetchSamples = async (sort: { column: string; order: SortOrder }) => {
        try {
            if (loading) return;
            setLoading(true);
            dispatch({type: "toggleSortLoading", payload: true});
            fetchCounts();
            fetchSite();
            setSamples([]);
            const {column, order} = sort;
            const {data} = await getSamples(
                page,
                keyword,
                activeTab,
                column,
                order === 0 ? "asc" : "desc"
            );
            setTotal(data.count);
            setSamples(data.results);
        } catch (err: any) {
            dispatch({
                type: "showAlert",
                payload: {
                    error: true,
                    message: err.message,
                },
            });
        } finally {
            setLoading(false);
            dispatch({type: "toggleSortLoading", payload: false});
        }
    };

    useEffect(() => {
        if (location.state) {

            const state: any = location.state;
            if (state.tab) {
                window.history.replaceState({}, document.title);
                changeState(state.tab);
                return;
            }
        }
        if (stateActiveTab) {
            changeState(stateActiveTab);
        } else {
            changeState(TabItem.SamplesToReceive);
        }
        // eslint-disable-next-line
    }, [location]);

    useEffect(() => {
        fetchSamples(sort[activeTab]);
        // eslint-disable-next-line
    }, [activeTab, page, keyword, sort]);

    const toggleChecked = (sample: any, checked: boolean) => {
        const has = checkedSamples.some((s) => s.sample_id === sample.sample_id);
        if (has && !checked) {
            setCheckedSamples((samples) =>
                samples.filter((s) => s.sample_id !== sample.sample_id)
            );
        } else if (!has && checked) {
            setCheckedSamples((samples) => [...samples, sample]);
        }
    };

    const canAction = [
        TabItem.SamplesToReceive,
        TabItem.SamplesToDispose,
    ].includes(activeTab);

    const sampleLength =
        currentTab === TabItem.SamplesToReceive && showAwaitingToTransport
            ? samples.length
            : samples.filter((sample) => sample.state !== "FILLED").length;

    return (
        <div className="samples">
            <div className="tophead">
                <div className="resources-link">
                    <h2 className="title">Hi, {site?.name || ""}!</h2>
                    <div className="divider">&nbsp;</div>
                    <div>
                        <a href="https://www.drugchecking.community" target="_blank" rel="noreferrer">
                            Resources <Icons.ExternalLink style={{marginRight: "7px", padding: "3px"}}/>
                        </a>
                    </div>
                </div>
            </div>
            <NavigationBar>
                <NavigationTab
                    label="Samples To Receive"
                    active={activeTab === TabItem.SamplesToReceive}
                    count={counts?.to_receive}
                    onClick={() => changeState(TabItem.SamplesToReceive)}
                />
                <NavigationTab
                    label="Samples To Report"
                    active={activeTab === TabItem.SamplesToReport}
                    count={counts?.to_analyze}
                    onClick={() => changeState(TabItem.SamplesToReport)}
                />
                <NavigationTab
                    label="Samples To Dispose"
                    active={activeTab === TabItem.SamplesToDispose}
                    count={counts?.to_dispose}
                    onClick={() => changeState(TabItem.SamplesToDispose)}
                />
                <NavigationTab
                    label="All Samples"
                    active={activeTab === TabItem.AllSamplesAnalysis}
                    onClick={() => changeState(TabItem.AllSamplesAnalysis)}
                />
            </NavigationBar>
            <Row classList="withtoggle" border={RowBorder.Rounded}>
                <SearchField
                    key={activeTab}
                    value={keyword}
                    loading={loading}
                    onChange={(keyword: string) => changeState(activeTab, 1, keyword)}
                    placeholder="Search sample ID"
                />
                {activeTab === TabItem.SamplesToReceive && (
                    <ToggleSwitch
                        on={showAwaitingToTransport}
                        onToggle={(status: boolean) => setShowAwaitingToTransport(status)}
                        label={`Show Awaiting Transport (${
                            samples.filter((s) => s.state === "FILLED").length
                        })`}
                    />
                )}
            </Row>
            <Row classList="header" border={RowBorder.Top}>
                {canAction && (
                    <div
                        className="th pointer"
                        onClick={() => {
                            const nonFilledSamples = samples.filter(
                                (sample: any) => sample.state !== "FILLED"
                            );
                            if (nonFilledSamples.length === checkedSamples.length) {
                                setCheckedSamples([]);
                            } else {
                                setCheckedSamples(nonFilledSamples);
                            }
                        }}
                        style={{width: "5%", color: "#044554"}}
                    >
                        All
                    </div>
                )}
                <div className="th" style={{width: "10%"}}>
                    Sample ID{" "}
                    <Sortable id="sample_id" label="Sample ID" type={SortType.Alphabet}/>
                </div>
                <div className="th">
                    Status{" "}
                    <Sortable id="status" label="Status" type={SortType.OldestNewest}/>
                </div>
                <div className="th">
                    Sample Type{" "}
                    <Sortable
                        id="sample_type"
                        label="Sample Type"
                        type={SortType.Alphabet}
                    />
                </div>
                <div className="th">
                    Expected Drug(s){" "}
                    <Sortable
                        id="expected_drugs"
                        label="Expected Drug(s)"
                        type={SortType.Alphabet}
                    />
                </div>
                {activeTab !== TabItem.SamplesToReceive && (
                    <div className="th">
                        Date Received{" "}
                        <Sortable
                            id="date_received"
                            label="Date Received"
                            type={SortType.OldestNewest}
                        />
                    </div>
                )}
                {activeTab === TabItem.SamplesToReceive && (
                    <div className="th">
                        Delivery Method{" "}
                        <Sortable
                            id="track_number"
                            label="Delivery Method"
                            type={SortType.Alphabet}
                        />
                    </div>
                )}
            </Row>
            {sampleLength === 0 && (
                <Row classList="full" border={RowBorder.Bottom}>
                    <div className="text-center">
                        {loading ? "Loading..." : "No samples to show"}
                    </div>
                </Row>
            )}
            {sampleLength > 0 && (
                <div
                    className={`rows ${total > perPage ? "withPagination" : ""} ${
                        checkedSamples.length > 0 && canAction ? "rmb" : ""
                    }`}
                >
                    {samples.map((sample: any, index: number) => {
                        if (sample.state === "FILLED") {
                            if (showAwaitingToTransport && sample.track_number === null) {
                                return (
                                    <Row
                                        key={index}
                                        classList="lowopac"
                                        border={
                                            total > perPage ||
                                            index < samples.length - 1 ||
                                            samples.length < 5
                                                ? RowBorder.None
                                                : RowBorder.Bottom
                                        }
                                    >
                                        <div className="td" style={{width: "5%"}}></div>
                                        <div className="td" style={{width: "10%"}}>
                                            {sample.sample_id}
                                        </div>
                                        <div className="td">
                                            <SampleStatus sample={sample}/>
                                        </div>
                                        <div className="td">
                                            {getUserFacingSampleType(sample.sample_type)}
                                        </div>
                                        <div className="td">
                                            <div className="tag">{sample.expected_drugs[0]}</div>
                                            {sample.expected_drugs.length > 1 && (
                                                <span>+{sample.expected_drugs.length - 1} More</span>
                                            )}
                                        </div>
                                        {activeTab === TabItem.SamplesToReceive && (<div className="td">
                                            {sample.state === "FILLED"?'N/A':sample.track_number ? 'Third Party' : 'Internal'}
                                        </div>)}

                                    </Row>
                                );
                            }
                            return null;
                        }

                        const onClick = (e: any) => {
                            history.push(`/result/${sample.sample_id}`, {tab: activeTab});
                        };
                        const sampleIsChecked = checkedSamples.some(
                            (s) => s.sample_id === sample.sample_id
                        );
                        return (
                            <Row
                                key={index}
                                classList={sampleIsChecked ? "pointer selected" : "pointer"}
                                border={
                                    total > perPage ||
                                    index < sampleLength - 1 ||
                                    sampleLength < 5
                                        ? RowBorder.None
                                        : RowBorder.Bottom
                                }
                            >
                                {canAction && (
                                    <div
                                        className="td"
                                        style={{width: "5%"}}
                                        onClick={() => toggleChecked(sample, !sampleIsChecked)}
                                    >
                                        <Checkbox
                                            checked={sampleIsChecked}
                                            onChange={(checked: boolean) =>
                                                toggleChecked(sample, checked)
                                            }
                                        />
                                    </div>
                                )}
                                <div
                                    className="td"
                                    onClick={onClick}
                                    style={{fontWeight: "bold", width: "10%"}}
                                >
                                    {sample.sample_id}
                                </div>
                                <div className="td" onClick={onClick}>
                                    <SampleStatus sample={sample}/>
                                </div>
                                <div className="td" onClick={onClick}>
                                    {getUserFacingSampleType(sample.sample_type)}
                                </div>
                                <div className="td" onClick={onClick}>
                                    <div className="tag">{sample.expected_drugs[0]}</div>
                                    {sample.expected_drugs.length > 1 && (
                                        <span>+{sample.expected_drugs.length - 1} More</span>
                                    )}
                                </div>
                                {activeTab !== TabItem.SamplesToReceive && (
                                    <div className="td" onClick={onClick}>
                                        {sample.date_received
                                            ? moment(sample.date_received)
                                                .format("MMMM DD, YYYY")
                                                .toString()
                                            : ""}
                                    </div>
                                )}

                                {activeTab === TabItem.SamplesToReceive && (<div className="td">
                                    {sample.track_number ? 'Third Party' : 'Internal'}
                                </div>)}
                            </Row>
                        );
                    })}
                    {sampleLength < 5 && (
                        <Row border={RowBorder.Bottom} classList="last"/>
                    )}
                </div>
            )}
            {total > perPage && (
                <Row classList="paginator" border={RowBorder.Bottom}>
                    <Paginator
                        onPageChange={(p: number) => changeState(activeTab, p, keyword)}
                        page={page}
                        perPage={perPage}
                        total={total}
                        disabled={loading}
                    />
                </Row>
            )}
            {checkedSamples.length > 0 && canAction && (
                <div className="footerbanner">
                    <span>{checkedSamples.length} Sample(s) Selected</span>
                    <div className="buttons">
                        <button
                            className="btn btn2 mr-seven"
                            onClick={() => setCheckedSamples([])}
                        >
                            Clear Selection
                            <Icons.CloseBtn style={{marginLeft: "0.5rem"}}/>
                        </button>
                        <button
                            className="btn btn1"
                            onClick={() => {
                                activeTab === TabItem.SamplesToReceive
                                    ? history.push("/receipt-samples", {
                                        checked_samples: checkedSamples,
                                        samples,
                                    })
                                    : history.push("/dispose-samples", {
                                        checked_samples: checkedSamples,
                                    });
                            }}
                        >
                            {activeTab === TabItem.SamplesToReceive
                                ? "Confirm Receipt Of Samples"
                                : "Dispose Samples"}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ManageSamplesAnalysis;
